/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import "@ionic/angular/css/palettes/dark.system.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  src: url("assets/fonts/inter/Inter_18pt-Regular.ttf");
  font-family: "inter-regular"
}

@font-face {
  src: url("assets/fonts/inter/Inter_18pt-SemiBold.ttf");
  font-family: "inter-semibold"
}

@font-face {
  src: url("assets/fonts/AppleColorEmoji.ttf");
  font-family: "AppleColorEmoji";
}

@font-face {
  src: url("assets/fonts/sherika/Sherika-Regular.otf");
  font-family: "sherika-regular";
}

@font-face {
  src: url("assets/fonts/sherika/Sherika-Bold.otf");
  font-family: "sherika-bold"
}

:root {
  --accent: #f07569;
  --accent-light: #ffe8dc;
  --accent-strong: #f35e50;
  --subtle: #f4f4f4;
  --strong: #000;
  --normal: #444;
  --muted: #888;
  --light: #ccc;
  --highlight: #8c4a6e66;
  --highlight-strong: #8c4a6e;
  --danger: #F32013;
  --danger-strong: #d1160d;
}

html,
body,
* {
  font-family: 'inter-regular', "Helvetica Neue", "Roboto", 'AppleColorEmoji', "Noto Color Emoji", sans-serif;
  box-sizing: border-box;
}

ion-button[fill=clear] {
  --color: var(--accent);
}

ion-button[fill=default],
ion-button[fill=solid],
ion-button:not([fill=clear], [fill=outline]) {
  --color: var(--subtle);
  --background: var(--accent)
}

ion-content {
  &::-webkit-scrollbar {
    display: none;
  }
}

::selection {
  @apply bg-[#f0756950]
}

input, textarea, ::placeholder, .label {
  font-size: 0.875rem;
}

.focusable {
  @apply outline-none shadow-sm rounded-lg px-4 py-2 transition-all focus:enabled:shadow-[0_0_0px_3px_var(--highlight)] focus-within:enabled:shadow-[0_0_0px_3px_var(--highlight)] border-[1.5px] border-[var(--light)] hover:enabled:border-[var(--muted)] text-black;
}

.absolute-center {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}